import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "h-screen flex flex-col font-montserrat relative" }
const _hoisted_2 = { class: "w-full h-full bg-white justify-center items-center flex flex-col mx-auto my-20 text-12px xxl:text-14px overflow-hidden" }
const _hoisted_3 = { class: "absolute w-full bottom-0 mb-12" }
const _hoisted_4 = { class: "border-t border-gray-lp-200 border-b overflow-y-auto h-screen pb-24" }
const _hoisted_5 = { class: "absolute w-full bottom-0 mb-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_TableLazyLoad = _resolveComponent("TableLazyLoad")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_TableV1 = _resolveComponent("TableV1")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Header, { currentRoutes: _ctx.getCurrentRoute }, null, 8, ["currentRoutes"]),
    _withDirectives(_createVNode(_component_TableLazyLoad, { columns: _ctx.columns }, null, 8, ["columns"]), [
      [_vShow, _ctx.isLoading]
    ]),
    _withDirectives(_createVNode(_component_ErrorMessage, {
      errorType: _ctx.errorCause,
      onTryAgain: _ctx.getBalanceList
    }, null, 8, ["errorType", "onTryAgain"]), [
      [_vShow, _ctx.isError && !_ctx.isLoading]
    ]),
    _withDirectives(_createVNode("div", _hoisted_2, [
      _createVNode(_component_ErrorMessage, { errorType: "lastPage" }),
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_Pagination, {
          onNext: _ctx.nextPage,
          onPrevious: _ctx.prevPage,
          page: _ctx.page,
          toPage: _ctx.toPage
        }, null, 8, ["onNext", "onPrevious", "page", "toPage"])
      ])
    ], 512), [
      [_vShow, _ctx.balanceData.length === 0 && !_ctx.isLoading && _ctx.page !== 1]
    ]),
    _withDirectives(_createVNode("div", _hoisted_4, [
      _createVNode(_component_TableV1, {
        columns: _ctx.columns,
        data: _ctx.balanceData,
        onClick: _ctx.onClickRow,
        class: "my-8 table-fixed",
        isDetailAble: _ctx.isDetailAble
      }, null, 8, ["columns", "data", "onClick", "isDetailAble"])
    ], 512), [
      [_vShow, !_ctx.isLoading && _ctx.balanceData.length !== 0 && !_ctx.isError]
    ]),
    _withDirectives(_createVNode("div", _hoisted_5, [
      _createVNode(_component_Pagination, {
        onNext: _ctx.nextPage,
        onPrevious: _ctx.prevPage,
        page: _ctx.page,
        isButtonNext: _ctx.balanceData.length >= _ctx.limit,
        totalData: _ctx.balanceData.length,
        toPage: _ctx.toPage
      }, null, 8, ["onNext", "onPrevious", "page", "isButtonNext", "totalData", "toPage"])
    ], 512), [
      [_vShow, !_ctx.isLoading && _ctx.balanceData.length !== 0 && !_ctx.isError]
    ])
  ]))
}