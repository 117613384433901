
import { Options, Vue } from "vue-class-component";
import { BalanceLimitController } from "@/app/ui/controllers/BalanceLimitController";
import {
  checkRolePermission,
  formatDate,
  formatPriceIDR,
  formatPriceRM
} from "@/app/infrastructures/misc/Utils";
import { FINANCE_BALANCE_LIMIT } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {}
})
export default class Detail extends Vue {
  async created() {
    this.fetchBalanceData();
  }

  //Loading
  get isLoading() {
    return BalanceLimitController.isLoadingDetail;
  }

  get id() {
    return this.$route.params.id;
  }

  get isEditAble() {
    return checkRolePermission(FINANCE_BALANCE_LIMIT.EDIT);
  }

  fetchBalanceData() {
    this.getDetailBalanceData(this.$route.params.id);
  }

  getDetailBalanceData(id: any) {
    return BalanceLimitController.getBalanceDetail({ id });
  }

  //Error
  get isError() {
    return BalanceLimitController.isError;
  }

  //Error Cause
  get errorCause() {
    return BalanceLimitController.errorCause;
  }

  createdAt = "";
  createdBy = "";
  updateAt = "";
  updateBy = "";

  limit = "";
  minBalance = "";
  minWithdrawAmount = "";

  limitMY = "";
  minBalanceMY = "";
  minWithdrawAmountMY = "";

  get dataDetail() {
    const detail = BalanceLimitController.balanceDetail;
    this.createdAt = formatDate(detail.createdAt);
    this.createdBy = detail.createdBy;
    this.updateAt =
      detail.updatedAt === "0001-01-01T00:00:00Z" || detail.updatedAt === null
        ? formatDate(detail.createdAt)
        : formatDate(detail.updatedAt);
    this.updateBy = detail.updatedBy;
    this.limit = formatPriceIDR(detail.limit);
    this.minBalance = formatPriceIDR(detail.minBalance);
    this.minWithdrawAmount = formatPriceIDR(detail.minimumWithdrawAmount);

    this.limitMY = formatPriceRM(detail.limitMY);
    this.minBalanceMY = formatPriceRM(detail.minBalanceMY);
    this.minWithdrawAmountMY = formatPriceRM(detail.minimumWithdrawAmountMY);
    return detail;
  }

  goBack() {
    this.$router.push(`/finance/balance-limit`);
  }

  item = {
    status: "active"
  };

  // to Edit page
  goEdit(id: string) {
    this.$router.push(`/finance/balance-limit/${id}/edit`);
  }
}
