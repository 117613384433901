
import { Options, Vue } from "vue-class-component";
import TableV1 from "@/app/ui/components/table/index.vue";
import router from "@/app/ui/router";
import { BalanceLimitController } from "@/app/ui/controllers/BalanceLimitController";
import {
  checkRolePermission,
  formatPriceIDR
} from "@/app/infrastructures/misc/Utils";
import debounce from "lodash/debounce";
import { FINANCE_BALANCE_LIMIT } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {
    TableV1
  }
})
export default class Balance extends Vue {
  // get Data
  created() {
    this.getBalanceList();
    this.firstPage();
  }

  getBalanceList() {
    return BalanceLimitController.getBalanceList({
      page: 1,
      limit: 10
    });
  }

  get getCurrentRoute() {
    return this.$route.meta.title;
  }

  get isDetailAble() {
    return checkRolePermission(FINANCE_BALANCE_LIMIT.DETAIL);
  }

  firstPage() {
    return BalanceLimitController.setFirstPage();
  }

  get balanceData() {
    return BalanceLimitController.balanceData.data;
  }

  //Loading
  get isLoading() {
    return BalanceLimitController.isLoading;
  }
  elipsisDesc(item: any) {
    return item.description.length > 80
      ? `${item.description.substr(0, 80)}...`
      : item.description;
  }

  columns = [
    {
      name: "ID Limit Rules",
      key: "id",
      styleHead: "w-1/12 text-center whitespace-no-wrap",
      styleBody: "",
      render: (item: any) => {
        return `<span class='text-center text-red-lp-200 flex justify-center'>${item.id}</span>`;
      }
    },
    {
      name: "Nama Limit Rules",
      styleHead: "w-2/20 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: any) => {
        return `<span class="whitespace-no-wrap text-black-lp-300">
              ${item.name}</span>`;
      }
    },
    {
      name: "Deskripsi",
      key: "description",
      styleHead: "w-2/12 text-left",
      styleBody: "break-words",
      render: (item: any) => {
        return `<div class='pr-8 text-black-lp-300'>${this.elipsisDesc(item)}
        </div>`;
      }
    },
    {
      name: "Applied to",
      key: "appliedTo",
      styleHead: "w-1/12 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: any) => {
        return `<div class='text-black-lp-300 flex justify-start'><div>${item.appliedTo}</div></div>`;
      }
    },
    {
      name: "Min. Balance Saldo",
      key: "MinSaldo",
      styleHead: "w-1/12 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: any) => {
        return `<div class="text-black-lp-300">${formatPriceIDR(
          item.minBalance
        )}</div>`;
      }
    },
    {
      name: "Status",
      key: "status",
      styleHead: "w-1/12 text-left",
      styleBody: "",
      render: (item: any) => {
        return `
        <div class="flex">
      <div
        class="rounded-full px-2 py-0 ${
          item.status.toLowerCase() === "active"
            ? "bg-green-lp-300"
            : "bg-red-300"
        } ${
          item.status.toLowerCase() === "active"
            ? "text-green-lp-200"
            : "text-red-lp-200"
        }"
      ><span class="capitalize">${item.status.toLowerCase()}</span></div></div>`;
      }
    }
  ];

  // To Detail
  onClickRow(item: any) {
    router.push(`/finance/balance-limit/${item.id}`);
  }

  // Pagination
  get page() {
    return BalanceLimitController.page;
  }
  get limit() {
    return BalanceLimitController.limit;
  }
  nextPage() {
    BalanceLimitController.setNextPage();
  }

  prevPage() {
    BalanceLimitController.setPrevPage();
  }

  toPage = debounce(value => {
    BalanceLimitController.setPageAction(value);
  }, 500);

  //Error
  get isError() {
    return BalanceLimitController.isError;
  }

  //Error Cause
  get errorCause() {
    return BalanceLimitController.errorCause;
  }
}
