
import { Options, Vue } from "vue-class-component";
import Input from "@/app/ui/components/input/index.vue";
import router from "@/app/ui/router";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import {
  formatDate,
  formatInputMoney,
  formatPriceWithoutCurrency,
  formatInputMoneyWithDecimal
} from "@/app/infrastructures/misc/Utils";
import { BalanceLimitController } from "@/app/ui/controllers/BalanceLimitController";

@Options({
  components: {
    Input,
    Tooltip
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSave() && !this.openModalSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      BalanceLimitController.setOpenModalSuccess(false);
      next();
    }
  }
})
export default class Edit extends Vue {
  created() {
    this.fetchBalanceData();
  }

  goBack() {
    this.$router.back();
  }

  id() {
    return this.$route.params.id;
  }

  item = {
    status: "Active"
  };

  //Loading
  get isLoading() {
    return BalanceLimitController.isLoadingDetail;
  }

  fetchBalanceData() {
    this.getDetailBalanceData(this.$route.params.id);
  }

  getDetailBalanceData(id: any) {
    return BalanceLimitController.getBalanceDetail({ id });
  }

  //Error
  get isError() {
    return BalanceLimitController.isError;
  }

  //Error Cause
  get errorCause() {
    return BalanceLimitController.errorCause;
  }

  createdAt = "";
  createdBy = "";
  updateAt = "";
  updateBy = "";

  limit: any = 0;
  minBalance: any = 0;
  minWithdrawAmount: any = 0;

  limitMY: any = 0;
  minBalanceMY: any = 0;
  minWithdrawAmountMY: any = 0;

  get dataDetail() {
    const detail = BalanceLimitController.balanceDetail;
    this.createdAt = formatDate(detail.createdAt);
    this.createdBy = detail.createdBy;
    this.updateAt =
      detail.updatedAt === "0001-01-01T00:00:00Z" || detail.updatedAt === null
        ? formatDate(detail.createdAt)
        : formatDate(detail.updatedAt);
    this.updateBy = detail.updatedBy;
    this.typeName = detail.limit;
    this.minBalance = formatPriceWithoutCurrency(detail.minBalance);
    this.minWithdrawAmount = formatPriceWithoutCurrency(
      detail.minimumWithdrawAmount
    );

    // ringgit
    this.typeNameMY = detail.limitMY;
    this.minBalanceMY = formatPriceWithoutCurrency(detail.minBalanceMY);
    this.minWithdrawAmountMY = formatPriceWithoutCurrency(
      detail.minimumWithdrawAmountMY
    );
    return detail;
  }

  // batas Limit dropdown
  typeSelect = false;
  onOpenTypeSelect() {
    this.typeSelect = true;
  }
  onCloseTypeSelect() {
    this.typeSelect = false;
  }
  typeName = 0;
  limitValue = this.dataDetail.limit;
  onSelectType(name: string, value: number) {
    this.onCloseTypeSelect();
    this.typeName = value;
    this.limitValue = value;
  }

  typeData = [
    { name: "50.000", value: 50000 },
    { name: "100.000", value: 100000 },
    { name: "200.000", value: 200000 }
  ];

  isDisabled = true;

  // Toggle Route
  openRoute = false;
  setRoute(bool: boolean) {
    this.openRoute = bool;
  }
  onOpenRoute(e: any) {
    this.isRoute()
      ? this.setRoute(true)
      : BalanceLimitController.setRoute(true);
    this.filterInput(e);
  }
  onCloseRoute() {
    this.openRoute = false;
  }
  isRoute() {
    return BalanceLimitController.isRoute;
  }
  changeRoute() {
    BalanceLimitController.setRoute(!this.isRoute());
    this.openRoute = false;
    this.messageSuccess = `Detail Limit Rules "${this.dataDetail.name}" berhasil diubah`;
    this.openSuccess = true;
  }

  get description() {
    return BalanceLimitController.description;
  }

  inputDescription(value: any) {
    BalanceLimitController.setDescription(value);
  }

  setMinBalanceValue(value: any) {
    BalanceLimitController.setMinBalance(value);
  }

  // Modal Success after Save
  get openModalSuccess() {
    return BalanceLimitController.openModalSuccess;
  }
  onCloseModalSuccess() {
    router.push(`/finance/balance-limit/${this.id()}`);
  }

  // Modal Success
  messageSuccess = `Detail Limit Rules "${this.dataDetail.name}" berhasil diubah`;
  openSuccess = false;

  // Save data
  openSave() {
    return BalanceLimitController.openModal;
  }
  onOpenSave() {
    BalanceLimitController.setOpenModal(true);
  }
  onCloseSave() {
    BalanceLimitController.setOpenModal(false);
  }

  _onEdit() {
    this.onCloseSave();
    BalanceLimitController.editBalance({
      limitId: Number(this.id()),
      limitDescription: this.description,
      limitMinBalance: Number(this.minBalance.replace(".", "")),
      limitBorder: this.typeName,
      status: this.isRoute() ? "active" : "inactive",
      limitMinimumWithdrawAmount: Number(
        this.minWithdrawAmount.replace(".", "")
      ),
      isCodDelivery: !!this.dataDetail.isCodDelivery,
      limitMinBalanceMY: Number(this.minBalanceMY.replace(".", "").replace(",", ".")),
      limitBorderMY: this.typeNameMY,
      limitMinimumWithdrawAmountMY: Number(
        this.minWithdrawAmountMY.replace(".", "").replace(",", ".")
      ),
    });
  }

  typeSelectMY = false;
  onOpenTypeSelectMY() {
    this.typeSelectMY = true;
  }
  onCloseTypeSelectMY() {
    this.typeSelectMY = false;
  }
  typeNameMY = 0;
  limitValueMY = this.dataDetail.limitMY;
  onSelectTypeMY(name: string, value: number) {
    this.onCloseTypeSelectMY();
    this.typeNameMY = value;
    this.limitValueMY = value;
  }

  typeDataMY = [
    { name: "15.7", value: 15.7 },
    { name: "31.3", value: 31.3 },
    { name: "62.5", value: 62.5 }
  ];

  // Close when changed
  isEditTerminated = false;
  newPath = "";
  answer = false;

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  filterInput(e: any) {
    e.target.value = e.target.value.replace(/\D+/g, "");
  }

  isOpenTooltip = false;

  openTooltip() {
    this.isOpenTooltip = true;
  }

  closeTooltip() {
    this.isOpenTooltip = false;
  }

  formatRupiah(value: any) {
    return formatInputMoney(value.replace(/[\D]+/g, ""));
  }

  onCheckedCODDeliveryPartner(value: any) {
    this.dataDetail.isCodDelivery = value;
  }

  formatRinggit(value: any) {
    return formatInputMoneyWithDecimal(value);
  }

}
